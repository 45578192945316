import ApiService from "./api.service"

export default class RoutesService extends ApiService
{
    path = 'routes';

    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }
    checkExisting(params) {
        return this.http.get(`${this.url}/check-existing`, { params: params } );
    }
    async sidWaypoints(params) {
        return await this.http.get(`${this.url}/sid-waypoints`, { params: params } );
    }
    async starWaypoints(params) {
        return await this.http.get(`${this.url}/star-waypoints`, { params: params } );
    }
}